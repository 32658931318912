import React from 'react';
import Footer from '~components/Footer/Footer.component';
import styles from '~components/Layout/Layout.styles.scss';

export default function Layout({ children }) {
    return (
        <div className={styles.layout}>
            <div className={styles.body}>
                <div className={styles.content}>
                    <main>{children}</main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
