import React from 'react';
import styles from '~components/Footer/Footer.styles.scss';

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <small>&copy; {new Date().getFullYear()} Christopher Aguilar</small>
        </footer>
    );
}
